body {
    margin: 0;
    background-color: #FFF;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;

    &:fullscreen {
        .game-appbar {
            display: none;
        }
    }
}

#root {
    margin: 0 auto;
    max-width: 1300px;
}

.page-wrapper {
    padding-top: 10px;
    padding-bottom: 80px;
    background-color: #FFF;
}

.flex-started-parent {
    display: flex;
    justify-content: left;
    align-items: center;
}

.flex-centered-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modified-number-indicator {
    font-size: 17px;
    color: #EEE;
    vertical-align: top;
}

.dark-mode {
    background-color: #333;

    .page-wrapper {
        background-color: #333;
    }

    .modified-number-indicator {
        color: #111;
    }
}

.test-mode {
    .page-wrapper {
        min-height: 100vh;
        background-color: #C83;
    }

    .test-mode-panel {
        display: block;
    }
}

.test-mode-panel {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 150;
    text-align: center;
    font-size: 20px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 10;
    transition: top 0.4s ease-in;

    &.collapsed {
        top: -50px;
    }
}